import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  color: white;   
  flex-direction: column; 
  /* background-color: green; */
  text-align: center; 

 
`;

const List = styled.div`
  display: flex;    
  flex-direction: column;    
  /* align-items: center; */
`;

const ListItem = styled.div`
  width: 100%;
  max-width: 300px;  
`;

const Image = styled.img`
  margin-top: 0.5rem; 
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
`;

const ImageSquare = styled.img`
  /* margin-top: 0.5rem;    */
  /* height: auto; */
  width: 100%;
  /* object-fit: cover; */
  /* aspect-ratio: 2/1; */
`;

const Music = () => {
  return (
    <Container>
      <h3>MUSIC:</h3>
      <List>
        <ListItem>
          <a target='_blank' href="https://www.youtube.com/watch?v=w4-y5wX87jA&list=PLHaHoyEs2YfMABw-UPe4_iKQLseEXZcHa">
            <Image src="images/album_inner.jpg" alt="Image 1" />
          </a>
          <p>Inner Suffering (2022)</p>
        </ListItem>
        <ListItem>
          <a target='_blank' href="https://www.youtube.com/watch?v=UyKoRy8IPVY&list=PLHaHoyEs2YfMvLsKqWd6v6-dt_m3gSEGz">
            <Image src="images/album_abduction.jpg" alt="Image 2" />
          </a>
          <p>Abduction Now (2009)</p>
        </ListItem>
        <ListItem>
          <a target='_blank' href="https://youtu.be/AqnJwqvK-_U">
            <ImageSquare src="images/album_rehearsal.png" alt="Image 3" />
          </a>
          <p>Lost Tapes (2003)</p>
        </ListItem>
        <ListItem>
          <a target='_blank' href="https://www.youtube.com/watch?v=vL2dMspshQ0&list=PLHaHoyEs2YfN43-NJF4c0e6fovnih9dIX">
            <Image src="images/album_espiritual.jpg" alt="Image 4" />
          </a>
          <p>Mundo Espiritual (2003)</p>
        </ListItem>
      </List>
    </Container>
  );
};

export default Music;
