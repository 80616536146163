import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

const ModalWrapper = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--red-bg);
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: black;
  padding: 16px;
  position: relative;
  border-radius: 8px;
  width: auto;
  height: auto;

  @media (max-width: 600px) {
    /* width: 85%;
    max-height: 85%; */
    overflow-y: auto;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -15px;
  right: -15px;
  width: 3rem;
  height: 3rem;
  border: 3px solid black;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  color: black;
  cursor: pointer;

  @media (max-width: 600px) {    
    width: 2.5rem;
    height: 2.5rem;
    right: 0px;
    top: 0px;
  }
`;

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsMounted(false);
    if (onClose) {
      onClose();
    }
  };

  if (!isMounted) {
    return null;
  }

  return (
    <ModalWrapper isOpen={isOpen}>
      <ModalContent>
        <CloseButton onClick={handleClose}>X</CloseButton>
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
