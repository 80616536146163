import styled from 'styled-components';

export const Container = styled.nav`
     display: flex;         
     justify-content: center;
     flex-direction: column;
     text-align: center;     
     margin: 0rem;
     color: white;
`;

export const SocialMidiaContainer = styled.div`
    display: flex;    
    flex-direction: row;    
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0;
    color: white;    
`

export const Link = styled.a.attrs({ target: '_blank' })`
    cursor: pointer;    
    text-decoration: none;    
    color: inherit;
`;

export const BandLogo = styled.img`
    width: 30rem;
    content: url('images/logo.png');

    @media (max-width: 800px) {
        width: 15rem;
    }
`
