import React from 'react'
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs';

const FacebookIcon = () => {
    return (        
       <BsFacebook style={{fontSize: '1.5rem'}}/>
    )
};

const InstagranIcon = () => {
    return (
        <BsInstagram style={{fontSize: '1.5rem'}}/>
    )
}

const YoutubeIcon = () => {
    return (
        <BsYoutube style={{fontSize: '1.5rem'}}/>
    )
}



export { FacebookIcon, InstagranIcon, YoutubeIcon }; 


