import React from 'react'
import styled from 'styled-components';
import { Feed, NewsData } from '../../pages/News/News.data';

const TweetContainer = styled.div`
  display: flex;  
  padding: 16px;
  border: 1px solid #ccc;
  background-color: #242526;  
  margin: 0.5rem;
  border-radius: 8px;
  justify-content: center;
  max-width:1000px;
  width: 100%;
  /* min-width: 23rem; */
  
  @media (max-width: 1200px) {          
        width:100%;        
        max-width:90%;
        /* background-color: red;   */
    }
`;

const TweetContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;  
`;

const Username = styled.span`
  font-weight: bold;
  margin-bottom: 4px;
`;

const TweetText = styled.p`
  margin: 0;
`;

const IframeContainer = styled.div`  
  margin-top: 1rem;
  min-height: 350px;
`;

const FeedItem: React.FC<Feed> = (props) => {
  return (
    <TweetContainer className="feed-container">
      {/* <Avatar src={avatar} alt="Avatar" /> */}

      <TweetContent>
        <small style={{ color: '#a11616' }}>{props.date}</small>
        <Username>{props.title}</Username>

        <TweetText>{props.text}</TweetText>

        {props.youtubeLink && (
          <IframeContainer className="video-container">
            <iframe
              width="100%"
              height="100%"
              src={props.youtubeLink}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </IframeContainer>
        )}

        {props.bodyContent}

      </TweetContent>
    </TweetContainer>
  );
};

export default FeedItem;