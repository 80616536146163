import React, { useState } from 'react';
import { Container, BandLogo, SocialMidiaContainer, Link } from './Header.elements';
import { YouTubeBackground } from './YouTubeBackground'

import { FacebookIcon, InstagranIcon, YoutubeIcon } from '../Icons/Icons';
import { Navbar, SocialMedia } from "../index";
import BackgroundVideo from './BackgroundVideo';


function Header() {

  const [activeMenu, setActiveMenu] = useState("Home");
  const handleMenuSelect = (menu: string) => {
    setActiveMenu(menu);
  };

  const menuItems = [
    // { text: "home", selected: activeMenu === "home" },        
    { text: "videos", selected: activeMenu === "videos" },
    { text: "music", selected: activeMenu === "music" },
    { text: "bio", selected: activeMenu === "bio" },
    { text: "band", selected: activeMenu === "band" },
    { text: "contact", selected: activeMenu === "contact" },
  ];

  return (
    <Container>
      <BackgroundVideo src="videos/inner_s.mp4">
        <BandLogo />
        <SocialMedia />
      </BackgroundVideo>
      {/* <Navbar menuItems={menuItems} onMenuSelect={handleMenuSelect} /> */}
    </Container>
  )
}

export default Header
