import React, { useState } from 'react'
import styled from 'styled-components';
import News from '../News/News';

const HomeContainer = styled.section`
    /* background-color: blue;    */    
    display: flex;
`

function Home() {
    return (
        <HomeContainer>            
            <News/>
        </HomeContainer>
    )
}

export default Home
