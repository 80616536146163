import styled from "styled-components";

export const ContainerPage = styled.div`
    display: flex;
    color: white;    
    padding: 1rem;
    border: 1px solid #ccc;            
    border-width: 0;
    column-gap: 1rem;
    /* background-color: purple; */
    max-width: 1000px;
    gap: 3rem;

    @media (max-width: 1200px) {
        flex-direction: column;
        /* background-color: red; */
    }

   
`

export const MainContainer = styled.div`
    border-top: 1px solid #ccc;    
    border-bottom: 1px solid #ccc;
    justify-content: center;
    display: flex;
`;

export const DivContainerCenter = styled.div`   
    color: white; 
    width: 100vw;
    display: flex;    
`;

export const LeftSide = styled.div`
    /* background-color: green; */
    display: flex;
    justify-content: end;
    /* width: 60%; */
    @media (max-width: 1200px) {                
         justify-content: center;        
    }
`;

export const RightSide = styled.div`
    /* background-color: yellow;     */
    /* width: 40%; */
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 1200px) {                
         justify-content: center;        
    }
`;

