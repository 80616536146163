import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  color: white;   
  flex-direction: column; 
  /* background-color: green; */
  text-align: center; 
`;

const List = styled.div`
  display: flex;    
  flex-direction: column;    
  /* align-items: center; */
`;

const ListItem = styled.div`
  width: 100%;
  max-width: 300px;  
`;

const Image = styled.img`
  margin-top: 0.5rem; 
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
`;


function Merchandise() {
    return (
        <Container>
            <h3>MERCHANDISE:</h3>
            <List>
                <ListItem>
                    <a target='_blank' href="https://mojostamp.com.br/camiseta-vismal-1d6ch">
                     <Image src="images/camiseta.jpg" alt="T-shirt" />
                     <button>Comprar camiseta</button>
                    </a>
                </ListItem>
            </List>
        </Container>
    );
}

export default Merchandise;
