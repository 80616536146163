import { useState } from "react";

import "./App.css";
import * as S from "./style";
import { Concerts, Home, Music } from "./pages/index";
import { Header, Footer, Merchandise, Navbar } from "./components";

function App() {
  return (
    <>
      <Header />      
      <S.MainContainer>
        <S.ContainerPage>
          <S.LeftSide>
            <Home />
          </S.LeftSide>
          <S.RightSide>            
            <Music />
            <Merchandise/>
          </S.RightSide>
          {/* <Home /> */}
          {/* <Music /> */}
          {/* <Concerts /> */}
        </S.ContainerPage>
      </S.MainContainer>
      <Footer />
    </>
  );
}

export default App;
