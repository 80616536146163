import React from 'react';
import { BandLogo } from '../Header/Header.elements'
import { SocialMedia } from '../../components';

const Footer = () => {

    const packageInfo = require('../../../package.json');

    return (
        <div style={{ color: 'white', textAlign: "center", padding: '1rem' }}>
            <BandLogo />
            <SocialMedia />
            <p>vismalmetal@gmail.com</p>
            <p>Last update - 07/08/2023 - Bruno Alvarez</p>
            {/* <p>Package Name: {packageInfo.name}</p> */}
            <p>Version: {packageInfo.version}</p>
        </div>
    )
}

export default Footer
