import React from 'react'
import styled from 'styled-components';
import { FacebookIcon, InstagranIcon, YoutubeIcon } from '../Icons/Icons';

export const SocialMidiaContainer = styled.div`
    display: flex;    
    flex-direction: row;    
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0;
    color: white;    
`;
export const Link = styled.a.attrs({ target: '_blank' })`
    cursor: pointer;    
    text-decoration: none;    
    color: inherit;
`;


const SocialMedia = () => {
  return (
    <SocialMidiaContainer>
    <Link href='https://youtube.com/@vismal'>
      <YoutubeIcon />
    </Link>
    <Link href='https://www.facebook.com/profile.php?id=100066856882210'>
      <FacebookIcon />
    </Link>
    <Link href='https://www.instagram.com/vismalmetal/'>
      <InstagranIcon />
    </Link>
  </SocialMidiaContainer>    
  )
}

export default SocialMedia
