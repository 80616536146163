import styled from "styled-components";

export const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  /* padding-right: 1rem;      */
  /* background-color: blue; */
`;

export const TitleCenterDiv = styled.div`
  width: 100%;
  text-align: center;
`;

export const Image = styled.img`
  border-radius: 8px;
      width: 100%;
  height: auto;
  margin-top: 16px;
     cursor: pointer;

  /* max-height: 1000px;
    max-width: 1000px; */
`;

export const ImageModal = styled.img`
  max-height: 90vh;
  max-width: 90vw;
`;

export const ImageResponsive = styled(Image)`
  max-height: 1000px;
  max-width: 1000px;
`;
